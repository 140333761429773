.title-div
{
    text-align: center;
}

.title
{
    color: #ff2c02;
    font-size: calc(140px + (120 - 140) * ((100vw - 300px) / (1600 - 300)));
    margin: calc(150px + (50 - 150) * ((100vw - 300px) / (1600 - 300))) auto 150px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
}

.search-bar-home
{
    width: 1000px;
    margin: auto;
}

.home-page-footer-div
{
    position: fixed;
    left: 2.5%;
    bottom: 0px;
    width: 95%;
}

@media screen and (max-width: 600px)
{
    .search-bar-home
    {
        margin-top: 400px;
    }
}